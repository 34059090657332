import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ResourceApiService from '../api/ResourceApiService';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Document, Page, pdfjs } from 'react-pdf';

require('pdfjs-dist')

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function Resource() {
    const params = useParams();

    /**
     * @type{ResourceDto}
     */
    const [resource, setResource] = useState();
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    const resourceId = params.resourceId;
    const resourceApiService = new ResourceApiService();

    const navigate = useNavigate();

    function getAttachmentFileExtension() {
        const fileNameSplit = resource?.resourceAttachment.fileName.split('.');
        return fileNameSplit[fileNameSplit.length - 1];
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    function onDocumentLoadSuccess(numPages) {
        setNumPages(numPages._pdfInfo.numPages);
    }

    async function loadResource() {
        const thisResource = await resourceApiService.getResource(resourceId);

        if(thisResource.success) {
            setResource(thisResource.data);
        }
    }

    function onItemClick(itemPageNumber) {
        setPageNumber(itemPageNumber);
    }

    useEffect(() => {
        loadResource();
    }, []);

    return(
        <Box>
            <Button variant={'contained'} onClick={() => navigate('/resources')} sx={{ marginTop: '10px' }}>Return to resources</Button><br/>
                <img
                    style={{ maxWidth: '100%' }}
                    alt={''}
                    src={resource?.resourceImage.url}
                />
            <Grid container columnSpacing={0.5} sx={{ marginTop: '10px' }}>
                <Grid item>

                </Grid>
                <br/><br/>
                <Grid container item xs={12} direction={'column'} sx={{
                    marginRight: '10px',
                    height: '100%',
                }}>
                    <div className={'content'} dangerouslySetInnerHTML={{ __html: resource?.rsContent }}/>
                </Grid>
                {resource?.resourceAttachment &&
                    <Grid direction={'column'} container item xs={10}>
                        {resource.resourceAttachment.fileName.includes('.pdf') &&
                            <center>
                                <div>
                                    <Document file={resource.resourceAttachment.url}
                                              onLoadSuccess={onDocumentLoadSuccess}>
                                        <Page pageNumber={pageNumber} renderTextLayer={false}
                                              renderAnnotationLayer={false}/>
                                    </Document>
                                    <div>
                                        <p>
                                            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                                        </p>
                                        <button
                                            type={'button'}
                                            disabled={pageNumber <= 1}
                                            onClick={previousPage}
                                        >
                                            Previous
                                        </button>
                                        <button
                                            type={'button'}
                                            disabled={pageNumber >= numPages}
                                            onClick={nextPage}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </center>
                        }
                        {!resource.resourceAttachment.fileName.includes('.pdf') &&
                            <img
                                alt={''}
                                src={resource.resourceAttachment.url}
                            />
                        }
                    </Grid>}
            </Grid>
        </Box>

    )
}
